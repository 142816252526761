import { createStyles, Theme } from '@material-ui/core/styles';

export const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {},
    errorHandler: {
      minHeight: '100vh',
      padding: spacing(0, 8),
    },
  });
