import { PaletteOptions } from '@material-ui/core/styles/createPalette';

import { colours, legacyColours } from '@shared/theme/colours';

export const initializePalette = (config?: PaletteOptions): PaletteOptions => {
  return {
    primarySpecial: {
      light: colours.salmon[400],
      main: colours.salmon[500],
      dark: colours.salmon[600],
    },
    primary: {
      light: legacyColours.blue.light,
      main: colours.navy[950],
      dark: legacyColours.blue.darkish,
    },
    secondary: {
      main: legacyColours.white.lightest,
      light: legacyColours.blue.main,
    },
    ...config,
  };
};
