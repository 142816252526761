import { CssBaseline } from '@material-ui/core';
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Tooltip,
} from 'chart.js';
import { lazy, useContext, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { RouteProps, useHistory } from 'react-router-dom';
import { IntercomProvider } from 'react-use-intercom';

import { getBaseUrl } from '@core/base-url';
import { ConfigContext, useLandlordConstants } from '@core/contexts/ConfigContext';
import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import ROUTES from '@landlord/routes/routes.constant';
import { browserEventActions } from '@redux/reducers/browserEvent/browserEventReducer';
import { Confirmation } from '@shared/components/confirmation';
import { ErrorHandler } from '@shared/components/error-handler';
import { Layout } from '@shared/components/layout';
import LazyLoad from '@shared/components/lazy-load';
import { Notification } from '@shared/components/notification';
import { sendGaEvent, useGoogleAnalytics } from '@shared/hooks/googleAnalytics';
import { useHotjar } from '@shared/hooks/useHotJar';

import { styles } from './App.styles';

const MarketplaceMembers = lazy(() => import('@landlord/modules/marketplace-members'));
const MarketplaceEvents = lazy(() => import('@landlord/modules/marketplace-events'));
const Disposals = lazy(() => import('@landlord/modules/disposals'));
const Viewings = lazy(() => import('@landlord/modules/viewings'));

export type AppProps = WithStyles<typeof styles>;

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Filler
);

const AppComponent: React.FC<AppProps> = ({ classes }) => {
  const { constants } = useLandlordConstants();
  const { config, error } = useContext(ConfigContext);
  const dispatch = useDispatch();
  const history = useHistory();

  useGoogleAnalytics(() => [
    {
      gtagOptions: { send_page_view: false, user_id: config.user.id },
      trackingId: config.googleAnalyticsTrackingId,
    },
  ]);

  useHotjar({ hotjarId: constants.HOTJAR_ID });

  // Listen for changes on history
  useEffect(() => {
    let sendGaTimeout: number | undefined;

    const unregister = history.listen((location) => {
      if (history.action === 'POP') {
        dispatch(browserEventActions.backForwardBrowserEvent(location.search));
      } else {
        dispatch(browserEventActions.urlBrowserEvent());
      }

      // Submit page views
      clearTimeout(sendGaTimeout);
      sendGaTimeout = setTimeout(() => sendGaEvent(location.pathname, location.search), 100);
    });

    return () => {
      clearTimeout(sendGaTimeout);
      unregister();
    };
  }, [history, dispatch]);

  const routes = useMemo(() => {
    const iasAccess = config.featuresEnabled.landlordApp.ias_access;

    const module: Array<{
      access: boolean;
      routeProps: MergeTypes<{ component: React.ComponentType<any> }, Omit<RouteProps, 'component'>>;
    }> = [
      {
        access: iasAccess,
        routeProps: { path: ROUTES.marketplace.marketplace_members, component: MarketplaceMembers },
      },
      {
        access: iasAccess,
        routeProps: { path: ROUTES.marketplace.marketplace_events, component: MarketplaceEvents },
      },
      {
        access: true,
        routeProps: { path: ROUTES.organisation.disposals.root, component: Disposals },
      },
      {
        access: config.featuresEnabled.landlordApp.tasks,
        routeProps: { path: ROUTES.organisation.viewings.root, component: Viewings },
      },
      {
        access: true,
        routeProps: {
          path: ROUTES.logOut,
          component: () => {
            window.location.href = `${getBaseUrl()}logout`;
            return null;
          },
        },
      },
    ];

    return module.filter(({ access }) => access).map(({ access, ...otherProps }) => otherProps);
  }, [config.featuresEnabled.landlordApp.ias_access, config.featuresEnabled.landlordApp.tasks]);

  if (error) {
    return (
      <ErrorHandler
        heading={error || `Sorry, we're having a few technical troubles right now.`}
        classes={{ root: classes.errorHandler }}
      />
    );
  }

  return (
    <IntercomProvider
      appId={config.intercomId}
      autoBoot={config.intercomEnabled}
      autoBootProps={{
        // Keep consistent with agency AngularJS app + agency React app
        email: config.user.email,
        name: config.user.name,
        userHash: config.intercom_hash,
        userId: config.user.id.toString(),
      }}
    >
      <CssBaseline />
      <Confirmation />
      <Notification />
      <Layout googleMapsApiKey={constants.GOOGLE_MAPS_HTTP_API}>
        <LazyLoad withFallback={false}>
          <Switch>
            {/* Routes */}
            {routes.map(({ routeProps: { component: $component, ...otherRouteProps } }) => (
              <Route key={String(otherRouteProps.path)} component={$component} {...otherRouteProps} />
            ))}
            <Redirect exact from={ROUTES.initial} to={ROUTES.organisation.disposals.root} />
            <Redirect to={ROUTES.defaultRedirect} />
          </Switch>
        </LazyLoad>
      </Layout>
    </IntercomProvider>
  );
};

export const App = withStyles(styles)(AppComponent);
